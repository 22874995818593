var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parsedCorrectly)?_c('li',[_c('router-link',{attrs:{"to":{
      name: 'collection',
      query: {
        url: _vm.example.url
      }
    }}},[_c('Thumbnail',{attrs:{"image":_vm.parsedImage},on:{"fetch-embedded":_vm.onFetchEmbedded,"error":_vm.onError}})],1),_c('div',{staticClass:"links"},[_c('div',[_c('div',{staticClass:"resource-title"},[_c('strong',{attrs:{"title":_vm.example.title}},[_vm._v(_vm._s(_vm.example.title))])]),_c('div',[_vm._v(" From: "),_c('em',[_c('a',{attrs:{"href":_vm.example.collection.url}},[_vm._v(_vm._s(_vm.example.collection.title))])])])]),_c('div',[_c('router-link',{attrs:{"to":{
          name: 'collection',
          query: {
            url: _vm.example.url
          }
        }}},[_vm._v(" Georeference this IIIF resource")]),_vm._v(" → ")],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }