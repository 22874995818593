var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background"},[_c('section',{staticClass:"container below-header"},[_c('ol',{staticClass:"images"},_vm._l((_vm.imagesById),function(image,imageId){return _c('li',{key:imageId,class:{
          active: _vm.activeImageId === imageId
        }},[_c('router-link',{attrs:{"to":{
            name: 'collection',
            query: {
              image: imageId,
              url: _vm.$route.query.url
            }
          }},nativeOn:{"dblclick":function($event){return _vm.goToMask(imageId)}}},[_c('Thumbnail',{attrs:{"imageId":imageId,"image":image.parsedImage},on:{"fetch-embedded":_vm.onFetchEmbedded,"error":_vm.onError}})],1),_c('div',{staticClass:"border"}),_c('div',{staticClass:"icons"},[_c('img',{class:{ present: _vm.hasGcps(imageId) },attrs:{"alt":"Image contains georeferenced map","src":require("../assets/icon-georeferenced.svg")}}),_c('img',{class:{ present: _vm.hasResourceMask(imageId) },attrs:{"alt":"Image contains masked map","src":require("../assets/icon-masked.svg")}})])],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }