var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"padding"},[_c('div',{staticClass:"drawer box padding"},[(_vm.drawerOpen)?[(_vm.drawerOpen === 'metadata')?_c('Metadata'):(_vm.drawerOpen === 'maps')?_c('Maps'):(_vm.drawerOpen === 'annotation')?_c('Annotation'):_vm._e(),_c('hr')]:_vm._e(),_c('div',{staticClass:"base"},[_c('div',{staticClass:"padding"},[(_vm.label)?_c('h3',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"select-image"},[(_vm.activeImage && _vm.imageCount > 1)?[_c('span',[_vm._v(" Image "+_vm._s(_vm.activeImage.index + 1)+"/"+_vm._s(_vm.imageCount)+" ")]),_c('div',{staticClass:"buttons prev-next-buttons"},[_c('b-button',{attrs:{"size":"is-small","icon-left":"arrow-left","disabled":!_vm.activeImage.previousImageId,"tag":"router-link","type":"is-link","to":{
                  name: _vm.$route.name,
                  query: {
                    url: _vm.$route.query.url,
                    image: _vm.activeImage.previousImageId
                  }
                }}}),_c('b-button',{attrs:{"size":"is-small","icon-left":"arrow-right","disabled":!_vm.activeImage.nextImageId,"tag":"router-link","type":"is-link","to":{
                  name: _vm.$route.name,
                  query: {
                    url: _vm.$route.query.url,
                    image: _vm.activeImage.nextImageId
                  }
                }}})],1)]:_vm._e()],2)]),_c('div',{staticClass:"controls"},[_c('div',{staticClass:"buttons drawer-buttons"},[(_vm.hasMetadata)?_c('b-button',{attrs:{"active":_vm.drawerOpen === 'metadata',"icon-right":"info"},on:{"click":function($event){return _vm.toggleDrawer('metadata')}}}):_vm._e(),_c('b-button',{attrs:{"active":_vm.drawerOpen === 'maps',"icon-right":"list"},on:{"click":function($event){return _vm.toggleDrawer('maps')}}}),_c('b-button',{attrs:{"active":_vm.drawerOpen === 'annotation',"icon-right":"code"},on:{"click":function($event){return _vm.toggleDrawer('annotation')}}})],1)])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }