import { fetchJson } from '../../lib/api.js'

const state = () => ({
  mapsByImageId: {}
})

const getters = {}

const actions = {
  async fetchMaps({ commit }, { url }) {
    try {
      const maps = await fetchJson(url)
      commit('setMaps', { maps })
    } catch (err) {
      console.error(`Error fetching ${url}:`, err.message)
    }
  }
}

const mutations = {
  setMaps(state, { maps }) {
    state.mapsByImageId = {}

    for (let map of maps) {
      const imageId = map.resource.id

      if (!state.mapsByImageId[imageId]) {
        state.mapsByImageId[imageId] = []
      }

      state.mapsByImageId[imageId].push(map)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
